@mixin media-breakpoint-up($name) {
    @if map_has_key($grid-breakpoints, $name) {
        @media (min-width: map_get($grid-breakpoints, $name)) {
            @content;
        }
    } @else {
        @media (min-width: $name) {
            @content;
        }
    }
}
