@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,400;1,600;1,700;1,800;1,900&display=swap');
@import "variables";
@import "mixin";
@import "grid";

*,
*::before,
*::after {
  box-sizing: border-box;
}

body, html {
  margin: 0;
}

body {
  font-family: $base-font-family;
  font-weight: 300;
  color: $gray;
  background-color: $body-bg;
  overflow-x: hidden;
  overflow-y: auto;

  &.modal-active {
    overflow-y: hidden;

    main {
      opacity: 0;
    }
  }
}

::selection {
  color: $white !important;
  background-color: $red !important;
}

::-moz-selection {
  color: $white !important;
  background-color: $red !important;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

button {
  &:focus {
    outline: none;
  }
}

ul {
  list-style: none;
}

h1 {
  font-weight: 900;
  text-transform: uppercase;
  color: $title-color;
  position: relative;
  transition: all .3s;

  span {
    display: inline-block;
  }

  @include media-breakpoint-up(420px) {
    font-size: 30px;
  }

  @include media-breakpoint-up(md) {
    font-size: 56px;
    line-height: 49px;

    &:after {
      content: attr(title);
      position: absolute;
      font-size: 60px;
      opacity: 0.02;
      font-weight: bold;
      left: 20px;
      top: 5px;
      white-space: nowrap;

      @include media-breakpoint-up(md) {
        font-size: 178px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    font-size: 86px;
    line-height: 70px;
  }

  @media (min-width: 1870px) {
    font-size: 143px;
    line-height: 112px;
  }
}

h1, h2, h3, h4, h5 {
  margin-top: 0;
}

img {
  max-width: 100%;
  display: inline-block;
  vertical-align: top;
  height: auto;
}

.flex {
  display: flex;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

p {
  font-size: 16px;
  margin: 0;

  @include media-breakpoint-up(480px) {
    font-size: 18px;
  }
}

.wrapper {
  min-height: 100vh;
  background: $white;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

main {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 72px 0 50px;
  transition: opacity .7s;

  @include media-breakpoint-up(l) {
    padding: 0 0 140px;
    flex-direction: row;
  }
}

.hide {

  @include media-breakpoint-up(l) {
    display: none !important;
  }
}

.text-justify {
  text-align: justify;
}

h2 {
  font-weight: 900;
  font-size: 32px;
  line-height: 38px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: $red;

  @include media-breakpoint-up(md) {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 40px;
  }
}


#home {
  align-self: center;
  padding-top: 0;

  .home-container {
    display: flex;
    justify-content: space-between;
  }

  .home-img-wrapper {
    display: flex;
    position: relative;
    padding-bottom: 15px;
    padding-left: 30px;

    @include media-breakpoint-up(xxl) {
      padding-left: 15px;
    }

    .home-img {
      position: absolute;
      display: none;
      right: -20px;
      top: 20px;
      z-index: 10;

      @include media-breakpoint-up(md) {
        display: block;
        max-width: 450px;
      }

      @include media-breakpoint-up(860px) {
        max-width: 490px;
      }

      @include media-breakpoint-up(l) {
        max-width: 570px;
        top: -50px;
      }

      @include media-breakpoint-up(xxl) {
        max-width: 650px;
        top: -60px;
      }

      @include media-breakpoint-up(1600px) {
        max-width: 800px;
        top: -80px;
        right: -90px;
      }
    }

    .right-dots {
      position: absolute;
      top: -110px;
      right: 270px;
      width: 130px;
      z-index: 5;

      @include media-breakpoint-up(md) {
        top: 24px;
        right: -10px;
        width: 190px;
      }

      @include media-breakpoint-up(xl) {
        top: 24px;
        right: 0;
        width: auto;
      }
    }
  }

  .home-img-mobile {
    display: inline-block;
    margin: 10px 0;
    position: relative;
    right: -20px;
    z-index: 6;

    @include media-breakpoint-up(660px) {
      right: -60px;
    }

    @include media-breakpoint-up(690px) {
      right: -90px;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: justify;
    color: $black;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 26px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 21px;
      line-height: 29px;
    }
  }

  h2 {
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      margin-bottom: 26px;
    }
  }

  .text-wrapper {
    padding: 0;
  }

  .left-dots {
    display: none;

    @include media-breakpoint-up(xl) {
      display: block;
    }
  }
}

#about {

  @include media-breakpoint-up(l) {
    margin-bottom: 0;
    padding-top: 130px;
  }

  .about-card {
    position: relative;

    @include media-breakpoint-up(xl) {
      padding-right: 60px;
    }

    .left-dots {
      position: absolute;
      bottom: -210px;
      left: -45px;
      display: none;

      @include media-breakpoint-up(l) {
        display: block;
      }
    }
  }

  .why__us-card {
    position: relative;

    .right-dots {
      position: absolute;
      top: 20px;
      right: -75px;
      width: 160px;

      @include media-breakpoint-up(l) {
        display: block;
        top: -550px;
        width: auto;
      }
    }
  }

  h4 {
    font-weight: 600;
    font-size: 21px;
    line-height: 29px;
    margin: 30px 0;
    color: $black;

    @include media-breakpoint-up(md) {
      margin: 15px 0;
    }
  }

  p {
    color: $black;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: justify;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 26px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 21px;
      line-height: 29px;
    }
  }

  .features__wrapper {
    justify-content: space-between;
    position: relative;
    z-index: 2;
  }

  .feature {
    box-shadow: 30px 30px 60px rgba(199, 42, 25, 0.1);
    padding: 15px 15px 0;
    margin: 0 0 15px;
    background: $white;
    position: relative;
    width: 100%;
    z-index: 2;

    @include media-breakpoint-up(xl) {
      padding: 30px 30px 0;
      width: calc(33% - 20px);
      margin: 0;
    }

    p {
      font-size: 16px;
      line-height: 22px;
      text-align: left;
    }
  }

  .btn-holder {
    position: relative;
    z-index: 11;
  }
}

#services {

  .container {
    position: relative;
  }

  h2 {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
    }
  }

  .js-btn-modal {
    margin: 20px 0 0;

    @include media-breakpoint-up(l) {
      display: none;
    }
  }

  .right-dots {
    position: absolute;
    top: -10px;
    right: 265px;
    width: 130px;

    @include media-breakpoint-up(md) {
      top: -520px;
      right: 15px;
      width: auto;
    }
  }

  .left-dots {
    position: absolute;
    top: 15px;
    left: -75px;
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  .services {
    width: 100%;
    box-shadow: 30px 30px 60px rgba(0, 0, 0, 0.1);
    border: 4px solid transparent;
    font-size: 16px;
    line-height: 22px;
    color: $black;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    & + .services {
      margin-top: 16px;

      @include media-breakpoint-up(l) {
        margin-top: 0;
      }
    }

    @include media-breakpoint-up(md) {
      width: calc(33.3% - 20px);
      margin: 0 0 20px;
    }

    @include media-breakpoint-up(xxl) {
      width: calc(18% - 38px);
      margin: 0;
    }

    &__wrapper {
      justify-content: space-between;
      background: $white;
      z-index: 10;
      position: relative;
      padding: 0;

      h4 {
        color: $black;
        font-weight: 700;
        font-size: 21px;
        line-height: 29px;
        align-self: baseline;
        margin-bottom: 0;
        text-transform: none;

        @include media-breakpoint-up(l) {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 22px;
          text-transform: uppercase;
        }

        @include media-breakpoint-up(l) {
          font-weight: 900;
        }

        @include media-breakpoint-up(1640px) {
          font-size: 30px;
          line-height: 41px;
          margin-bottom: 28px;
        }

        br {
          display: none;

          @include media-breakpoint-up(l) {
            display: inline-block;
          }
        }
      }

      p {
        font-size: 16px;
        line-height: 22px;
      }
    }

    @include media-breakpoint-up(l) {

      &:not(.hover-off):hover {
        border: 4px solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(to bottom, #FBFC45, #F5B935);
      }
    }

    &__unactive-content {
      opacity: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 100%;
      padding: 32px 24px;

      @include media-breakpoint-up(l) {
        min-height: 480px;
        padding: 30px 15px 0;
        align-items: center;
      }

      @include media-breakpoint-up(xl) {
        min-height: 562px;
      }

      img {
        height: 60px;
        margin-bottom: 20px;

        @include media-breakpoint-up(l) {
          margin-bottom: 0;
          height: auto;
        }
      }
    }

    &__active-content {
      background: $yellow;
      padding: 30px 15px;
      position: absolute;
      display: none;
      width: 100%;
      left: 0;
      top: 0;
      min-height: 100%;
      opacity: 0;
      z-index: -1;

      @include media-breakpoint-up(l) {
        display: block;
        z-index: 10;
      }
    }

    .hide {
      opacity: 0;
      height: 0;
      z-index: 0;
    }

    .show {
      opacity: 1;
      z-index: 1;
    }

    &__list {
      padding-top: 20px;
    }

    &__list-item {
      align-items: center;
      margin: 5px 0;

      img {
        height: 12px;
        width: 12px;
        margin-right: 10px;
      }
    }
  }

  .hover-off {
    border: 4px solid $yellow;
    overflow: hidden;

    .services__active-content {
      z-index: 2;
    }
  }
}

#pricing {
  align-self: center;

  @include media-breakpoint-up(l) {
    padding-top: 170px;
  }

  .right-dots {
    position: absolute;
    top: 590px;
    right: -20px;
    width: 150px;

    @include media-breakpoint-up(md) {
      top: 225px;
      right: 90px;
      width: auto;
    }
  }

  .left-dots {
    position: absolute;
    top: 80px;
    right: 0;
    width: 110px;

    @include media-breakpoint-up(md) {
      top: 120px;
      right: auto;
      left: -70px;
      width: 150px;
    }
  }

  .pricing {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    box-shadow: 30px 30px 60px rgba(199, 42, 25, 0.1);
    padding: 15px;

    @include media-breakpoint-up(sm) {
      width: calc(50% - 10px);
    }

    @include media-breakpoint-up(md) {
      width: 100%;
    }

    @include media-breakpoint-up(xl) {
      display: block;
      width: calc(50% - 20px);
      padding: 30px 10px 30px 20px;

      & + .pricing {
        margin-top: 0;
      }
    }

    &__icon {
      width: 60px;
      height: 60px;
      flex-shrink: 0;
      background: #fff;
      border-radius: 10px;
      box-shadow: 15px 15px 30px rgba(199, 42, 25, 0.1);
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-up(xl) {
        display: inline-block;
        box-shadow: none;
        vertical-align: top;
        margin: 0;
        background: none;
        width: auto;
        height: auto;
        border-radius: 0;
      }

      img {
        height: 32px;

        @include media-breakpoint-up(xl) {
          height: auto;
        }
      }
    }

    h5 {
      font-weight: 800;
      font-size: 16px;
      line-height: 22px;
      color: $black;
      margin: 0;

      @include media-breakpoint-up(md) {
        font-size: 21px;
        line-height: 29px;
        margin: 10px 0;
      }
    }

    h5.red {
      color: $red;
    }

    &__wrapper {
      justify-content: space-between;
      padding: 0;
      position: relative;
      z-index: 10;

      @include media-breakpoint-up(md) {
        background: $white;
        justify-content: flex-start;
      }

      @include media-breakpoint-up(xl) {
        justify-content: space-between;
      }
    }

    p {
      font-weight: 300;
    }
  }

  p {
    color: $black;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    text-align: justify;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 26px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 21px;
      line-height: 29px;
    }
  }

  .pricing__text-card {
    p {
      font-size: 18px;
      margin-bottom: 20px;
      line-height: 32px;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        margin-bottom: 25px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 24px;
        margin-bottom: 48px;
      }

      &:last-of-type {
        margin-bottom: 30px;
      }
    }
  }

  .pricing-card {

    @include media-breakpoint-up(md) {
      margin-top: 90px;
    }
  }
}

#contact {
  position: relative;

  h1 {
    margin: 70px 0 30px 0;
    font-size: 48px;
    line-height: 46px;
    z-index: 1;

    @include media-breakpoint-up(md) {
      font-size: 82px;
      line-height: 70px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 178px;
      line-height: 138px;
    }
  }

  p {
    color: $black;
    font-size: 21px;
    line-height: 29px;
    margin-bottom: 15px;
    font-weight: 600;

    a {
      color: $red;
    }
  }

  .white-input {
    border: 1px solid rgba(66, 66, 66, 0.1);
    box-sizing: border-box;
    box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    width: 100%;
    min-height: 60px;
    padding: 10px;
    font-size: 21px;
    line-height: 29px;
    position: relative;
    z-index: 11;
  }

  textarea {
    resize: none;
    overflow: auto;
    height: 240px;
    margin-bottom: 0;
  }

  .contact-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  .contact-img-wrapper {
    position: relative;
    padding-bottom: 15px;
    display: flex;
    order: -1;

    @include media-breakpoint-up(md) {
      order: 2;
    }

    .contact-img {
      position: absolute;
      left: 90px;
      width: 230px;
      top: 0;
      z-index: 10;

      @include media-breakpoint-up(md) {
        top: -15px;
        left: auto;
        right: -10px;
        width: 320px;
      }

      @include media-breakpoint-up(xl) {
        right: -10px;
        top: -5px;
        width: 500px;
      }
    }
  }

  .right-dots {
    position: absolute;
    top: 230px;
    right: 0;
    z-index: 5;
    display: none;

    @include media-breakpoint-up(l) {
      display: block;
    }
  }

  .left-dots {
    bottom: -40px;
    width: 110px;

    @include media-breakpoint-up(md) {
      width: auto;
    }
  }
}

#careers {
  position: relative;

  @include media-breakpoint-up(md) {
    padding-top: 100px;
  }

  .right-dots {
    position: absolute;
    top: -290px;
    right: 0;
    width: 160px;

    @include media-breakpoint-up(md) {
      top: 100px;
      right: 90px;
      width: auto;
    }
  }

  .left-dots {
    position: absolute;
    bottom: -90px;
    left: 0;
    width: 100px;

    @include media-breakpoint-up(md) {
      width: auto;
    }
  }

  .team__leader-info {

    h5 {
      text-decoration: underline;
    }
  }

  .career {
    width: 100%;
    box-shadow: 30px 30px 60px rgba(0, 0, 0, 0.1);
    padding: 32px 24px;
    margin-bottom: 16px;
    background: $white;
    position: relative;
    z-index: 20;

    @include media-breakpoint-up(md) {
      width: calc(33.3% - 30px);
      padding: 20px 20px 25px;
      margin-bottom: 30px;
    }

    h5 {
      color: $black;
      font-weight: 800;
      font-size: 21px;
      line-height: 29px;
      margin: 0;

      @include media-breakpoint-up(md) {
        margin: 10px 0;
      }
    }

    p {
      font-size: 16px;
      line-height: 22px;
      color: $black;
      margin: 5px 0 0;

      @include media-breakpoint-up(md) {
        margin: 15px 0 25px;
      }
    }

    &__wrapper {
      justify-content: space-between;
      padding: 0;
      position: relative;
      z-index: 10;
    }

    .btn-link {
      display: inline-block;
      margin-left: 0;
      margin-top: 15px;
      vertical-align: top;

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
  }

  .transition__block-left-right {
    position: fixed;
    top: 0;
    left: -110%;
    width: 100%;
    height: 100vh;
    background: $white;
    overflow-y: scroll;
    transition: ease-in-out 1s;
    padding-bottom: 20px;
    z-index: 100;

    header {
      padding-top: 0;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        margin-bottom: 60px;
        padding-top: 20px;
      }

      .close-btn {
        position: absolute;
        cursor: pointer;
        right: 17px;
        top: 18px;
        z-index: 1;

        img {
          width: 24px;
          height: 24px;
        }

        @include media-breakpoint-up(md) {
          right: 15px;
        }
      }
    }

    .carrer__description {
      text-align: justify;
      color: $black;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;

      @include media-breakpoint-up(md) {
        font-size: 21px;
        line-height: 29px;
      }

      .col-12:first-child {

        @include media-breakpoint-up(md) {
          padding-right: 120px;
        }
      }

      p {
        margin-bottom: 20px;
        font-weight: 400;
      }

      .career__list-item {
        margin-bottom: 10px;
        align-items: center;

        @include media-breakpoint-up(md) {
          margin-bottom: 20px;
        }

        img {
          width: 12px;
          height: 12px;
          margin-right: 10px;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .show {
    left: 0;
  }

  .all__vacancies {

    &-header.flex {
      @include media-breakpoint-up(l) {
        padding-top: 65px;
      }
    }

    .js-close-btn {
      top: 18px
    }
  }

  .btn-holder {
    display: flex !important;

    .btn-link {
      display: none;

      @include media-breakpoint-up(l) {
        display: inline-block;
      }
    }
  }
}

.js-modal {
  display: none;
  background: $white;
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  overflow-y: auto;
  padding: 40px 0;
  color: $black;
  z-index: 100;

  .container {
    position: relative;
  }

  p {
    margin: 0 0 16px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
  }

  h4 {
    font-size: 21px;
    line-height: 29px;
    font-weight: 800;
  }

  h5 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 16px;
  }

  .js-close-btn {
    position: absolute;
    cursor: pointer;
    right: 17px;
    top: -27px;
    z-index: 20;

    svg {
      width: 24px;
      height: 24px;

      @include media-breakpoint-up(l) {
        width: 42px;
        height: 42px;
      }
    }

    &:hover {

      svg path {
        fill: $red;
        fill-opacity: 1;
      }
    }

    @include media-breakpoint-up(md) {
      right: 15px;
    }
  }
}

#terms {

  p {
    margin: 0 0 16px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
  }

  h5 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 16px;
  }

  a {
    color: $red;
  }

  .right-dots {
    position: absolute;
    right: 0;
    top: -10px;
    width: 160px;

    @include media-breakpoint-up(md) {
      width: auto;
      right: 65px;
      top: 90px;
    }
  }
}

#mentions {
  @extend #terms;
}

.marked__list {
  margin-bottom: 20px;

  &-item {
    align-items: center;
    margin: 8px 0;

    img {
      height: 12px;
      width: 12px;
      margin-right: 10px;
    }

    p {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 0 !important;
    }
  }
}

.alfabetic-list {
  list-style: lower-alpha;
}

#portfolio {

  h1 {
    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
    }
  }
}

.heading-holder {
  opacity: 1;
  visibility: visible;
  transition: visibility .8s, opacity .8s;
}

.hidden {
  opacity: 0 !important;
  visibility: hidden !important;
}

.no-wrap {
  flex-wrap: nowrap;
}

.btn-link {
  text-transform: uppercase;
  font-weight: 800;
  transition: color .3s;
  color: $red;
  position: relative;
  margin-left: 40px;
  font-size: 12px;
  line-height: 16px;

  @include media-breakpoint-up(xl) {
    font-size: 18px;
    line-height: 25px;
    margin-left: 40px;
  }

  &:hover {
    &:after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      border-bottom: 4px solid $red;
      width: 100%;
    }
  }
}

.close-btn {
  flex-shrink: 0;

  img {
    width: 24px;
    height: 24px;
  }
}

.btn {
  display: inline-block;
  vertical-align: top;
  color: $white;
  background-color: $red;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  border: 1px solid $red;
  padding: 11px 27px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: .3s;
  z-index: 5;
  min-width: 140px;
  text-align: center;

  @include media-breakpoint-up(xl) {
    font-size: 18px;
    line-height: 25px;
    padding: 15px 27px;
    min-width: 220px;
  }

  span {
    position: relative;
    z-index: 5;
  }

  &:hover {
    background-color: $btn-hover;
    border-color: $btn-hover;
    box-shadow: 0px 15px 30px rgba(199, 42, 25, 0.2);
  }

}

.btn-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  transition: visibility .3s, opacity .3s, filter .3s;
  margin-top: 22px;

  @include media-breakpoint-up(md) {
    margin-top: 50px;
    flex-direction: column;
  }

  &.blur {
    filter: blur(5px);
  }

  @include media-breakpoint-up(420px) {
    flex-direction: row;
  }

  .btn-link {
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      margin-bottom: 25px;
    }

    @include media-breakpoint-up(420px) {
      margin-bottom: 0;
    }
  }
}

.transition-block {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  &:after,
  &:before {
    position: absolute;
    content: '';
    background-color: $black;
    height: 100%;
    opacity: 1;
    transition: transform 1s cubic-bezier(0.5, -0.6, 0.5, 1.5), opacity .3s .7s;
  }

  &:before {
    left: 0;
    right: 50%;
  }

  &:after {
    right: 0;
    left: 50%;
  }

  &.appearance {

    &:before,
    &:after {
      transform: translateY(0);
    }
  }

  &.disappear {
    &:before {
      transform: translateY(-100%);
    }

    &:after {
      transform: translateY(100%);
    }
  }

  &.appearance--equal-left {
    &:before {
      transform: translateX(-200%);
    }

    &:after {
      transform: translateY(100%);
    }
  }
}

#loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  &:after,
  &:before {
    position: absolute;
    content: '';
    background-color: $white;
    height: 100%;
    transition: transform .8s;
  }

  &:before {
    left: 0;
    right: calc(50% - 1px);
  }

  &:after {
    right: 0;
    left: 50%;
  }

  &.invisible {

    img {
      display: none;
    }

    &:before {
      transform: translateX(-100%);
    }

    &:after {
      transform: translateX(100%);
    }
  }
}

.page {
  width: 100%;
  padding-top: 60px;
  position: relative;
  transition: opacity .5s, visibility .5s;

  &:not(#home) {

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      padding-top: 140px;
    }

    @include media-breakpoint-up(l) {
      margin-bottom: 60px;
      padding-top: 30px;
    }

    .btn-holder {
      display: none;

      @include media-breakpoint-up(l) {
        display: flex;
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: 40px;
  }
}

.text-wrapper {
  max-width: 630px;
  position: relative;

  .left-dots {
    position: absolute;
    bottom: -210px;
    left: -75px;
  }
}

.main-video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;

}

.header {
  position: fixed;
  background-color: $white;
  width: 100%;
  z-index: 100;

  @include media-breakpoint-up(l) {
    background-color: transparent;
    position: relative;
  }

  .logo {

    img {
      height: 36px;
    }
  }

  .menu-toggler {
    position: relative;
    justify-content: center;
    align-items: flex-end;
    display: flex;
    width: 30px;
    height: 18px;
    background-color: transparent;
    flex-direction: column;
    border: 0;
    padding: 0;
    cursor: pointer;

    &:after,
    &:before {
      content: '';
      position: absolute;
      right: 0;
      width: 100%;
      height: 2px;
      background-color: $gray;
      border-radius: 5px;
      transition: transform .5s;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
      width: 20px;
    }

    span {
      width: 15px;
      height: 2px;
      background-color: $gray;
      border-radius: 5px;
      transition: visibility .5s, opacity .5s;
    }

    @include media-breakpoint-up(l) {
      display: none;
    }
  }

  .socials-list {
    justify-content: center;
    padding-top: 10px;
    margin-bottom: 32px;

    @include media-breakpoint-up(l) {
      display: none;
    }
  }

  &.open {

    nav {
      display: block;
      overflow-y: auto;
    }

    .menu-toggler {

      &:before {
        transform: rotate(45deg) translate(4px, 8px);
        width: 30px;
      }

      &:after {
        transform: rotate(-45deg) translate(3px, -8px);
        width: 30px;
      }

      span {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  nav {
    padding-bottom: 0;
    box-shadow: -1px 16px 8px -11px rgba(0, 0, 0, 0.45);

    @include media-breakpoint-up(l) {
      position: static;
      height: auto;
      width: auto;
      display: inherit;
      background-color: transparent;
      box-shadow: none;
      overflow: visible;
      padding-top: 0;
    }

    .js-close-btn {
      top: 18px;

      @include media-breakpoint-up(l) {
        display: none;
      }
    }
  }

  &-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    position: relative;

    @include media-breakpoint-up(md) {
      padding: 15px 0 28px;
    }

    &:after {
      position: absolute;
      content: '';
      left: -15px;
      right: -15px;
      bottom: 0;
      border-bottom: 1px solid rgba(140, 140, 140, 0.3);

      @include media-breakpoint-up(md) {
        left: -1000px;
        right: -1000px;
      }
    }
  }

  .nav-list {
    padding: 10px 0 20px;
    margin: 0;

    li.link-decor {
      display: none;

      @include media-breakpoint-up(l) {
        padding: 0;
        position: absolute;
        display: inherit;
        left: -12px;
        bottom: -8px;
        height: 4px;
        background-color: $red;
        visibility: visible;
        transition: transform 0.5s linear;
      }

      @include media-breakpoint-up(xl) {
        left: -10px;
      }
    }

    li {
      display: inline-block;
      width: 100%;

      @include media-breakpoint-up(l) {
        width: auto;

        &.mobile-link {
          display: none;
        }
      }

      a {
        color: $gray;
      }

      &.active {

        a {

          @include media-breakpoint-up(l) {
            color: $red;
          }
        }
      }
    }

    a {
      text-transform: uppercase;
      transition: color .3s;
      font-size: 32px;
      font-weight: 900;
      padding: 8px 0;
      display: inline-block;
      width: 100%;
      text-align: center;

      &.small-text {
        text-transform: none;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }

      @include media-breakpoint-up(l) {
        width: auto;
        font-weight: 700;
        font-size: 16px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 18px;
      }

      &:hover {
        color: $red;
      }
    }

    @include media-breakpoint-up(l) {
      margin: 0 -18px;
      position: relative;
      display: flex;
      padding: 0;

      li:not(.link-decor) {
        margin: 0 18px;
      }
    }

    @include media-breakpoint-up(xl) {
      margin: 0 -20px;

      li:not(.link-decor) {
        margin: 0 20px;
      }
    }
  }
}

footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 16;
  transition: opacity .6s, visibility .6s;
  background: $white;
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }

  .container {
    padding-bottom: 20px;
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-up(xl) {
      padding-bottom: 34px;

    }
  }

  .nav-list {
    padding: 0;
    display: flex;

    li {

      & + li {
        margin-left: 40px;
      }
    }

    a {
      font-size: 16px;
      line-height: 22px;
      color: $gray;
      transition: .2s;

      &:hover {
        color: $dark-hover;
      }
    }
  }
}

.info {
  display: flex;
  align-items: center;

  @include media-breakpoint-up(xl) {
    transform: translateY(-112px) translateX(118px) rotate(-90deg);

    &:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 60px;
      height: 1px;
      margin-left: 20px;
      background-color: rgba(140, 140, 140, 0.3);
    }
  }

  &.hidden {
    opacity: 1 !important;
    visibility: visible !important;
    @include media-breakpoint-up(md) {
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  p {
    font-size: 12px;
    text-transform: uppercase;
  }
}

.socials-list {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 0 10px;

  @include media-breakpoint-up(400px) {
    margin-bottom: 0;
  }

  a {
    filter: brightness(1);
    transition: filter .3s;

    &:hover {

      img {
        filter: brightness(0%) grayscale(100%);
      }
    }
  }

  li {
    padding: 0 15px;
  }
}

.about-card {

  &__content-inner {

    @include media-breakpoint-up(md) {
      padding-left: 28px;

    }
  }

  &__img-inner {
    overflow: hidden;
    border-radius: 50%;
    max-width: 200px;
    margin: 0 auto 25px auto;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      max-width: 350px;
    }

    img {
      width: 100%;
    }
  }

  p {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 32px;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      margin-bottom: 25px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 24px;
      margin-bottom: 48px;
    }

    &:last-of-type {
      margin-bottom: 30px;
    }

    span {
      color: $white;
      font-weight: 400;
    }
  }
}

@keyframes blur_on {
  0% {
    filter: blur(0)
  }
  100% {
    filter: blur(5px)
  }
}

@keyframes selectedAfterCard {
  0% {
    opacity: 1;
    transform: scale(1)
  }
  30% {
    opacity: .8;
    transform: scale(0.85)
  }
  99% {
    opacity: 0;
    transform: scale(10)
  }
  100% {
    opacity: 0;
    transform: scale(0)
  }
}

@keyframes imgHolderWrapperIn {
  0% {
    transform: scale(1)
  }
  30% {
    transform: scale(.9)
  }
  100% {
    transform: scale(1.5)
  }
}

@keyframes imgHolderWrapperOut {
  0% {
    transform: scale(1.5)
  }
  30% {
    transform: scale(1.7)
  }
  100% {
    transform: scale(1)
  }
}

@keyframes transition-card-content--out {
  0% {
    transform: translateY(0);
    opacity: 1
  }
  30% {
    transform: translateY(30px);
    opacity: .4
  }
  100% {
    transform: translateY(-150px);
    opacity: 0
  }
}

@keyframes transition-card-content--in {
  0% {
    transform: translateY(-150px);
    opacity: 0
  }
  30% {
    transform: translateY(-150px);
    opacity: 0
  }
  70% {
    transform: translateY(20px);
    opacity: .8
  }
  100% {
    transform: translateY(0);
    opacity: 1
  }
}

.transition-card {
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  top: 0;
  left: 0;

  @include media-breakpoint-up(420px) {
    padding-bottom: 16px;
  }

  @include media-breakpoint-up(md) {
    padding-top: 20px;
    padding-bottom: 32px;
  }

  &-row {
    margin: 0;

    @include media-breakpoint-up(md) {
      margin: 0 -($grid-gutters);
    }

    .col-xl-1:not(:first-child) {
      display: none;

      @include media-breakpoint-up(xl) {
        display: inherit;
      }
    }
  }

  &.transition:not(.selected) {
    transition: transform .3s;
  }

  &.end-transition {
    transition: transform 1s, margin-left 1s, top 1s, max-width 1s, width 1s, flex-basis 1s, max-height .9s, opacity .9s, filter .3s;

    .img-holder-wrapper {
      opacity: 1;
      transform: scale(1.5);
      transform-origin: left bottom;
      animation: imgHolderWrapperOut 1s forwards;
    }

    &:not(.selected) {
      .hidden-block {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 20px rgba(#000, 0.25);
    background: $black;
    opacity: 0;
    visibility: hidden;
    transition: visibility .3s, opacity .3s;
  }

  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    content: "";
    opacity: 0;
    visibility: hidden;
    z-index: 150;
    backdrop-filter: blur(5px);
    transition: visibility .3s, opacity .3s;
  }

  &.active {
    transform: scale(1.05);
    z-index: 20;

    &:after {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }

    .btn {
      opacity: 1;
      visibility: visible;
    }

    &.selected {
      box-shadow: none;
      background-color: transparent;
      overflow: visible;

      .img-holder-wrapper {
        opacity: 1;
        transform: scale(1);
        transform-origin: left bottom;
        animation: imgHolderWrapperIn 1s forwards;
      }
    }
  }

  &.blur {
    z-index: -1;

    &:before {
      opacity: 1;
      visibility: visible;
    }
  }

  figure {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    position: relative;
    z-index: 10;
    cursor: pointer;
  }

  .back-btn {
    background: transparent;
    border: 0;
    cursor: pointer;
  }

  .hidden-block {
    display: none;
  }

  .img-holder-wrapper {
    position: relative;
    z-index: 5;
  }

  .img-holder {
    max-width: 210px;
    width: 100%;
    margin: 0 auto 14px auto;
    position: relative;

    @include media-breakpoint-up(420px) {
      margin: 0 auto 30px auto;
    }

    &:last-child {
      position: absolute;
      left: 50%;
      top: 10px;
      opacity: .3;
      filter: blur(40px);
      z-index: -1;
      transform: translateX(-50%);
    }

    img {
      border-radius: 50%;
    }
  }

  figcaption {
    text-align: center;
    flex: 1 0 auto;

    @include media-breakpoint-up(420px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    @include media-breakpoint-up(md) {
      padding-left: 18px;
      padding-right: 18px;
    }
  }

  .btn {
    margin-top: 18px;
    opacity: 0;
    visibility: hidden;
    max-width: 200px;

    @media (max-width: 420px) {
      padding: 5px;
      font-size: 12px;
    }
  }

  .transition-card__content {
    display: flex;
    flex-direction: column;
    opacity: 1;
    transform: translateY(0);
  }

  .name {
    font-weight: 400;
    font-size: 14px;
    color: $white;
    margin-bottom: 7px;

    @include media-breakpoint-up(420px) {
      font-size: 24px;
      margin-bottom: 14px;
    }
  }

  .position {
    font-style: italic;
    font-size: 13px;

    @include media-breakpoint-up(420px) {
      font-size: 18px;
    }
  }
}

.rainmaker {
  background: radial-gradient(50% 50% at 50% 50%, #1F1F1F 0%, #000 100%);
}

.theluckfactory {
  background: radial-gradient(50% 50% at 50% 50%, #371413 0%, #220D0C 100%);
}

.empty {
  background: radial-gradient(50% 50% at 50% 50%, #1B1C1E 0%, #131415 100%);
  color: $gray-dark;
  font-size: 70px;
  font-weight: bold;

  @include media-breakpoint-up(420px) {
    font-size: 100px;
  }
}

.swarmiz {
  background: radial-gradient(50% 50% at 50% 50%, #E17538 0%, #AE3F00 100%);
}

.quanta {
  background: radial-gradient(50% 50% at 50% 50%, #68419E 0%, #5B3591 100%);
}

.management-row {
  margin: -20px 0 10px 0;

  @include media-breakpoint-up(xl) {
    margin: 0 0 30px 0;
  }
}

.portfolio-card {
  font-size: 24px;
  padding-bottom: 32px;
  margin-bottom: -50px;

  &.transition-card {
    .img-holder {
      margin: 0 auto 24px auto;
      border-radius: 50%;
      position: relative;

      &:last-child {
        position: absolute;
      }

      img, span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &.theluckfactory {
        img {
          max-width: 85%;
        }
      }

      &.quanta {
        img {
          max-width: 65%;
        }
      }

      img {
        border-radius: 0;
        max-width: 50px;

        @include media-breakpoint-up(420px) {
          max-width: 100%;
        }
      }

      &:after {
        content: '';
        display: table;
        padding-top: 100%;
      }
    }
  }

  &-row {
    margin-bottom: 20px;
    @include media-breakpoint-up(md) {
      margin-bottom: 50px;
    }
  }

  p {
    font-size: 24px;
    line-height: 34px;
    font-weight: 400;
  }

  .name {
    color: $white;
  }
}

.portfolio-row {
  margin-bottom: 16px;
}

blockquote {
  font-size: 18px;
  line-height: 34px;
  margin: 0 0 50px 0;
  position: relative;

  &:after,
  &:before {
    position: absolute;
  }

  &:before {
    right: 100%;
    top: -44px;
    @include media-breakpoint-up(md) {
      content: url("../images/quote_top.png");

    }
  }

  &:after {
    left: 100%;
    bottom: 30px;
    @include media-breakpoint-up(md) {
      content: url("../images/quote_bottom.png");
    }
  }

  p {
    margin-bottom: 10px;
    line-height: 28px;
    @include media-breakpoint-up(md) {
      margin-bottom: 24px;
      line-height: 34px;
    }
  }
}

cite {
  color: $white;
  font-style: italic;
  font-size: 18px;
}

.contact-text {
  line-height: 34px;
  font-weight: 400;
  font-size: 21px;
  margin-bottom: 64px;

  &-wrapper {
    margin-bottom: 35px;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  a {
    color: $white;
    transition: color .3s;

    &:hover {
      color: $red;
    }
  }

  p {
    line-height: 34px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.contact-btn-wrapper {
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
  }
}

.mapouter {
  position: relative;

  &:after {
    content: '';
    padding-top: 100%;
    display: table;
  }

  @include media-breakpoint-up(md) {
    position: absolute;
    text-align: right;
    height: 100%;
    width: 45%;
    top: 0;
    right: 0;
  }

  iframe {
    height: 100%;
    width: 100%;
  }
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 100%;
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}

.fake-map-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  opacity: 1;
  visibility: visible;
  cursor: pointer;
  transition: visibility .3s, opacity .5s;

  .fake-map {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.info-card {
  position: fixed;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: visibility .8s, opacity .8s;
  z-index: 25;
  display: flex;

  .container {
    margin-top: 72px;
    padding-top: 30px;
    background-color: $black;
    overflow-y: auto;
    width: 100%;

    @include media-breakpoint-up(md) {
      padding-top: 72px;
      margin-top: 102px;
    }

    & > .row {
      padding-bottom: 80px;
    }
  }

  h2 {
    color: $white;
    font-size: 30px;
    line-height: 40px;
    text-transform: uppercase;
    margin-bottom: 10px;

    @include media-breakpoint-up(420px) {
      font-size: 38px;
      line-height: 48px;
    }

    @include media-breakpoint-up(md) {
      font-size: 54px;
      line-height: 64px;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 30px;
    font-style: italic;
    text-transform: uppercase;
    margin-bottom: 20px;

    @include media-breakpoint-up(420px) {
      font-size: 28px;
      line-height: 32px;
    }

    @include media-breakpoint-up(md) {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 42px;
    }
  }

  p {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 20px;
    text-align: justify;

    @include media-breakpoint-up(sm) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .img-holder {
    max-width: 210px;
    width: 100%;
    margin: 0 auto 30px auto;

    @include media-breakpoint-up(md) {
      max-width: 420px;
    }
  }

  .portfolio-card-img-holder {
    max-width: 210px;
    position: relative;
    border-radius: 50%;

    @include media-breakpoint-up(md) {
      max-width: 320px;
    }

    img, span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      border-radius: 0;
      width: 43%;
    }

    &.theluckfactory {

      img {
        width: auto;
        max-width: 85%;
      }
    }

    &.quanta {

      img {
        width: auto;
        max-width: 65%;
      }
    }

    &.swarmiz {

      img {
        max-width: 55%;
      }

    }

    &:after {
      content: '';
      padding-top: 100%;
      display: table;
    }

    img {
      max-width: 50px;
      @include media-breakpoint-up(md) {
        max-width: 100%;
      }
    }
  }

  img {
    border-radius: 50%;
    width: 100%;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.info-back-btn {
  position: fixed;
  background: transparent;
  border: 0;
  cursor: pointer;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes zoom-in {
  0% {
    opacity: 0;
    transform: scale(0)
  }
  70% {
    opacity: .7;
    transform: scale(1.1)
  }
  100% {
    opacity: 1;
    transform: scale(1)
  }
}

.zoom-in {
  opacity: 0;
  transform: scale(0);

  &.animated {
    animation: zoom-in 1s forwards linear;
  }
}

@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(200px)
  }
  50% {
    opacity: .5;
    transform: translateX(100px)
  }
  100% {
    opacity: 1;
    transform: translateX(0)
  }
}

.slide-left {
  opacity: 0;
  transform: translateX(200px);

  &.animated {
    animation: slide-left 1s forwards linear;
  }

}

@keyframes slide-left--big {
  0% {
    opacity: 0;
    transform: translateX(300px)
  }
  50% {
    opacity: .5;
    transform: translateX(150px)
  }
  100% {
    opacity: 1;
    transform: translateX(0)
  }
}

.slide-left--big {
  opacity: 0;
  transform: translateX(300px);

  &.animated {
    animation: slide-left--big 1s forwards linear;
  }
}

@keyframes slide-left--bigger {
  0% {
    opacity: 0;
    transform: translateX(400px)
  }
  50% {
    opacity: .5;
    transform: translateX(200px)
  }
  100% {
    opacity: 1;
    transform: translateX(0)
  }
}

.slide-left--bigger {
  opacity: 0;
  transform: translateX(400px);

  &.animated {
    animation: slide-left--bigger 1s forwards linear;
  }
}

@keyframes slide-left--biggest {
  0% {
    opacity: 0;
    transform: translateX(500px)
  }
  50% {
    opacity: .5;
    transform: translateX(250px)
  }
  100% {
    opacity: 1;
    transform: translateX(0)
  }
}

.slide-left--biggest {
  opacity: 0;
  transform: translateX(500px);

  &.animated {
    animation: slide-left--biggest 1s forwards linear;
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100px)
  }
  70% {
    opacity: .7;
    transform: translateY(10px)
  }
  100% {
    opacity: 1;
    transform: translateY(0)
  }
}

.slide-down {
  opacity: 0;
  transform: translateY(-100px);

  &.animated {
    animation: slide-down 1s forwards linear;
  }
}

@keyframes slide-down--small {
  0% {
    opacity: 0;
    transform: translateY(-50px)
  }
  70% {
    opacity: .7;
    transform: translateY(8px)
  }
  100% {
    opacity: 1;
    transform: translateY(0)
  }
}

.slide-down--small {
  opacity: 0;
  transform: translateY(-50px);

  &.animated {
    animation: slide-down--small 1s forwards linear;
  }
}

@keyframes slide-up--small {
  0% {
    opacity: 0;
    transform: translateY(20px)
  }
  70% {
    opacity: .7;
    transform: translateY(-5px)
  }
  100% {
    opacity: 1;
    transform: translateY(0)
  }
}

.slide-up--small {
  opacity: 0;
  transform: translateY(20px);

  &.animated {
    animation: slide-up--small 1s forwards linear;
  }
}

@keyframes slide-up--big {
  0% {
    opacity: 0;
    transform: translateY(50px)
  }
  70% {
    opacity: .7;
    transform: translateY(-10px)
  }
  100% {
    opacity: 1;
    transform: translateY(0)
  }
}

.slide-up--big {
  opacity: 0;
  transform: translateY(50px);

  &.animated {
    animation: slide-up--big 1s forwards linear;
  }
}

@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateX(50px)
  }
  100% {
    opacity: 1;
    transform: translateX(0)
  }
}

.slide-right {
  opacity: 0;
  transform: translateX(50px);

  &.animated {
    animation: slide-right 1s forwards linear;
  }
}

@keyframes slide-right2 {
  0% {
    opacity: 0;
    transform: translateX(80px)
  }
  100% {
    opacity: 1;
    transform: translateX(0)
  }
}

.slide-right2 {
  opacity: 0;
  transform: translateX(80px);

  &.animated {
    animation: slide-right2 1s forwards linear;
  }
}

@keyframes slide-right3 {
  0% {
    opacity: 0;
    transform: translateX(100px)
  }
  100% {
    opacity: 1;
    transform: translateX(0)
  }
}

.slide-right3 {
  opacity: 0;
  transform: translateX(100px);

  &.animated {
    animation: slide-right3 1s forwards linear;
  }
}

@keyframes slide-right4 {
  0% {
    opacity: 0;
    transform: translateX(120px)
  }
  100% {
    opacity: 1;
    transform: translateX(0)
  }
}

.slide-right4 {
  opacity: 0;
  transform: translateX(120px);

  &.animated {
    animation: slide-right4 1s forwards linear;
  }
}

@keyframes slide-up-opacity {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-up-now-opacity {
  animation: slide-up-opacity .6s;
}

.fadeOut {
  opacity: 0;
  animation: fade 1.5s linear;
}

@keyframes fade {
  0% {
    opacity: 1
  }
  50% {
    opacity: 0.5
  }
  100% {
    opacity: 0
  }
}
