// Fonts
$nunito-font-family: 'Nunito Sans', sans-serif;
$base-font-family: $nunito-font-family;

// Colors
$white: #fafafa;
$gray: rgba(66, 66, 66, .5);
$gray-dark: #3D4043;
$gray-darker: #24212A;
$black: #282828;
$body-bg: #141317;
$dark-hover: #424242;
$yellow: #f5d63b;
$red: #C72A19;
$btn-hover: #FD877A;
$title-color: rgba(66, 66, 66, 0.25);

$container-max-width: 1770px;
$grid-columns: 12;

$grid-breakpoints: (
        sm: 576px,
        md: 768px,
        l: 1024px,
        xl: 1200px,
        xxl: 1366px
);
$grid-gutters: 15px;
$grid-gutters_md: 75px
