@import "variables";
@import "mixin";

.container {
  padding: 0 10px;
  margin: 0 auto;
  max-width: $container-max-width;

  @include media-breakpoint-up(md) {
    padding: 0 $grid-gutters;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -10px;

  @include media-breakpoint-up(md) {
    margin: 0 (-$grid-gutters);
  }
}

@for $i from 1 through $grid-columns {
  .col-#{$i} {
    max-width: 100% /  $grid-columns * $i;
    width: 100% /  $grid-columns * $i;
    flex: 0 0 100% /  $grid-columns * $i;
    padding: 0 $grid-gutters;
  }

  .offset-#{$i} {
    margin-left: 100% /  $grid-columns * $i;
  }
}

@each $item, $breakpoint in $grid-breakpoints {
  @media all and (min-width: $breakpoint) {
    @for $i from 1 through $grid-columns {
      .col-#{$item}-#{$i} {
        max-width: 100% / $grid-columns * $i;
        width: 100% / $grid-columns * $i;
        flex: 0 0 100% / $grid-columns * $i;
      }

      .offset-#{$item}-#{$i} {
        margin-left: 100% /  $grid-columns * $i;
      }
    }
  }
}

